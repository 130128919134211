import React, { useContext, useEffect, useState } from "react";
import Table from "./components/Table/Table";
import { Routes, Route } from "react-router-dom";
import Order from "./pages/Order";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "./components/Utility/Spinner";
import Header from "./components/Header/Header";
import { RefreshContext } from "./ContextProvider";

function App() {
	const { isAuthenticated, isLoading, loginWithRedirect, getIdTokenClaims } =
		useAuth0();
	const [selectedRows, setSelectedRows] = useState([]);
	const { setUserRoles } = useContext(RefreshContext);

	useEffect(() => {
		async function getUserRoles() {
			const idTokenClaims = await getIdTokenClaims();

			const roles = idTokenClaims["https://my-app.example.com/roles"];
			console.log("roles", roles);
			setUserRoles(roles);
		}
		if (isAuthenticated) getUserRoles();
	}, [isAuthenticated]);

	// If user is not authenticated and auth0 has loaded, redirect to login page
	if (!isAuthenticated && !isLoading) {
		loginWithRedirect();
		return;
	}

	// Show loading message while the Auth0 provider is loading
	if (isLoading) {
		return (
			<div className="flex h-screen items-center justify-center ">
				<div className="w-10 h-10 text-blue-400">
					<Spinner />
				</div>
			</div>
		);
	}

	return (
		<div className="flex flex-col relative text-neutral-800 h-full">
			<Header selectedRows={selectedRows} />
			<Routes>
				<Route path="/" element={<Table setSelectedRows={setSelectedRows} />} />
				<Route
					path="order/:orderId"
					element={<Order setSelectedRows={setSelectedRows} />}
				/>
				{/* <Route path="dashboard" element={<Dashboard />} /> */}

				{/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
				<Route
					path="*"
					element={
						<div className="h-40 flex justify-center items-center">
							Page not found
						</div>
					}
				/>
			</Routes>
		</div>
	);
}

export default App;
